import { PlatformLoadingView } from '@sequor/loading-screen';
import React, { Suspense, useEffect, useState } from 'react';
import { useGetLogin, StatusLogin, useGetSecurityStatus, SecurityStatus, useGetLoginReturnModel } from '@sequor/security'
import { showNotificationError } from '@sequor/helius-notification'
import { useSetLocale, useGetInternationalizationFeatures, useGetCurrentI18n } from '@sequor/internationalization'
import { Redirect } from 'react-router-dom';
import { LoadingOverlayContextProvider } from '@sequor/helius-loading-overlay';
import { DialogContextProvider } from '@sequor/helius-dialog';
import { parseToBool } from '@sequor/helpers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { SectionContextProvider } from '@sequor/helius-section'
import { TenantStatus, useGetTenantStatus } from '@sequor/tenants';

const MainRoot = React.lazy(() => import('@sequor/spa-main/dist/MainRoot'))

const MainStartup: React.FC = () => {

    const internationalizationFeatures = useGetInternationalizationFeatures()

    const currentLocale = useGetCurrentI18n()

    const status = useGetSecurityStatus()

    const loginReturnModel = useGetLoginReturnModel()

    const getLogin = useGetLogin()

    const setLocale = useSetLocale()

    const [state, setState] = useState(<PlatformLoadingView />)

    const loadCriteriaWizard = parseToBool(process.env.REACT_APP_LOAD_CRITERIA_WIZARD)

    const tenantStatus = useGetTenantStatus()

    useEffect(() => {
        if (tenantStatus === TenantStatus.Pending)
            setState(<Redirect to={{ pathname: '/tenants' }} />)
        else
        getLogin()
            .then(({ success, messages }) => {
                if (!success) {
                    if (messages && messages.length >= 1) {
                        let { code, text } = messages[0]

                        if (!text || text === '')
                            if (code === StatusLogin.LOGIN_NOK)
                                text = currentLocale.entries['messageOKLogin']
                            else if (code === StatusLogin.DISABLED_USER)
                                text = currentLocale.entries['messageDisabledUser']

                        showNotificationError(text)
                    }

                    let search = ''

                    if (window.location.href) {
                        const url = new URL(window.location.href)

                        for (const [name, value] of Array.from(url.searchParams)) {
                            search += `${(search !== '' ? '&' : '')}${name}=${value}`
                        }

                        search = `?${search}`
                    }

                    setState(<Redirect to={{ pathname: '/logon', search }} />)
                }
            })
    }, [])

    useEffect(() => {
        if (status === SecurityStatus.Authenticated) {
            const language = (loginReturnModel.language ?? "").trim()

            const locale = internationalizationFeatures.getLocale(language)

            if (locale && locale.code !== currentLocale.locale.code) {
                setLocale(language)
            }
            else {
                setState(<Suspense fallback={<PlatformLoadingView />}>
                    <SectionContextProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DialogContextProvider
                                i18n={currentLocale.entries as any}>
                                <LoadingOverlayContextProvider>
                                    <MainRoot loginReturn={loginReturnModel} loadWizard={loadCriteriaWizard} />
                                </LoadingOverlayContextProvider>
                            </DialogContextProvider>
                        </MuiPickersUtilsProvider>
                    </SectionContextProvider>
                </Suspense>)
            }
        }
    }, [status, currentLocale])

    return <React.Fragment>
        {state}
    </React.Fragment>
}

export default MainStartup

