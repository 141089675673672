import React from 'react';
import { AppearanceContextProvider } from '@sequor/helius-appearance'
import { NotificationProvider } from '@sequor/helius-notification';
import { InternationalizationContextProvider, useGetCurrentI18n } from '@sequor/internationalization'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import MainStartup from './Startup/MainStartup';
import SecurityStartup from './Startup/SecurityStartup';
import config from './core.lumen.ext.json';
import { AppConfiguration } from '@sequor/model';
import { PowerdBySequorLoadingView } from '@sequor/loading-screen';
import ViewVersion from './ViewVersion';
import { TenantContextProvider, useGetTenant } from '@sequor/tenants'
import { DateTimeContextProvider } from '@sequor/helius-field';
import { SecurityContextProvider } from '@sequor/security';
import TenantChoice from './Startup/TenantChoice/TenantChoice';

const SecurityLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  return <SecurityContextProvider>
    {children}
  </SecurityContextProvider>
}

const TenantLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  return <TenantContextProvider fallback={<PowerdBySequorLoadingView />}>
    {children}
  </TenantContextProvider>
}

const InternationalizationLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {

  const { locale } = useGetTenant()

  return <InternationalizationContextProvider
    fallback={<PowerdBySequorLoadingView />}
    locale={locale}>
    {children}
  </InternationalizationContextProvider>
}

const DateTimeLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {

  const { localeParameters } = useGetTenant()

  const currentI18n = useGetCurrentI18n()

  const localeConfig = (localeParameters ?? {})[currentI18n.locale.code]

  return <DateTimeContextProvider
    config={localeConfig?.dateTimeConfig}
    locale={currentI18n.locale.locale}
    i18n={currentI18n.entries as any}>
    {children}
  </DateTimeContextProvider>
}


const AppearanceLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {

  return <AppearanceContextProvider
    sources={{
      type: 'Default'
    }}
    fallback={<PowerdBySequorLoadingView />}>
    {children}
  </AppearanceContextProvider>
}

const App: React.FC = () => {

  const url = new URL(window.location.href)

  if (url.searchParams.has('viewversion')) {
    return <ViewVersion />
  }

  AppConfiguration.CoreConfiguration = config as any

  return <SecurityLoading>
    <TenantLoading>
      <InternationalizationLoading>
        <DateTimeLoading>
          <AppearanceLoading>
            <NotificationProvider>

              <BrowserRouter>
                <Switch>
                  <Route path="/tenants">
                    <TenantChoice />
                  </Route>
                  <Route path="/logon">
                    <SecurityStartup />
                  </Route>
                  <Route exact path="/">
                    <MainStartup />
                  </Route>
                  <Redirect from='*' to='/' />
                </Switch>
              </ BrowserRouter>

            </NotificationProvider>
          </AppearanceLoading>
        </DateTimeLoading>
      </InternationalizationLoading>
    </TenantLoading>
  </SecurityLoading>
}

export default App
